<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
        :data-source="sortedTimeSlots"
        :headers="headers"
        able-class="table-striped table-hover"
      >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-time-slot"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    <modal
      title="Ajout du type d'evenement"
      id="add-time-slot"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-day-of-week">Jour de la semaine</label>
          <select v-model="dayOfWeek" class="form-control" name="add-day-of-week" id="add-day-of-week" v-select="{placeholder:'Selectionnez le jour de la journée'}">
            <option value=""></option>
            <option v-for="(d,index) in daysOfWeek" :key="index" :value="d">{{ d }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="add-start-time">Heure de debut du RDV</label>
          <input
            id="add-start-time"
            name="add-start-time"
            type="text"
            class="form-control"
            v-model="startTime"
          >
        </div>
        
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>

    <modal
      title="Modification du créneau de rendez-vous"
      id="update-time-slot"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="updating"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="update-day-of-week">Jour de la semaine</label>
          <select v-model="dayOfWeek" class="form-control" name="update-day-of-week" id="update-day-of-week" v-select="{placeholder:'Selectionnez le jour de la journée'}">
            <option value=""></option>
            <option v-for="(d,index) in daysOfWeek" :key="index" :value="d">{{ d }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="update-start-time">Heure de debut du RDV</label>
          <input
            id="update-start-time"
            name="update-start-time"
            type="text"
            class="form-control"
            v-model="startTime"
          >
        </div>
        
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Navbar from '../../../components/navbar.vue';
import DataTable from '../../../components/dataTable/local'
import Modal from "../../../components/modal.vue"
import { Select } from '../../../directive/select';
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"
import { ADD_TIME_SLOT, UPDATE_TIME_SLOT } from '../../../graphql/events';
import { BOOLEAN_TYPE, COMPONENT_TYPE, TEXT_TYPE } from '../../../components/dataTable/dataType';
require("flatpickr/dist/themes/material_green.css");
const Actions = () => import('../../../components/admin/events/timeslotAction.vue')
export default {
    components: { Navbar, DataTable, Modal },
    directives: { Select }, 
    data(){
        return {
            navbarItems: [
                {   
                    libelle: "Rendez-vous", 
                    route: 'rendez_vous'
                },
                {
                    libelle: 'Créneaux de rendez-vous'
                }
            ],
            pageIcon: 'la-calendar',
            pageTitle: 'Créneau des Rendez-vous',
            pageDescription: 'List des créneaux pour les rendez-vous client CASEAC',
            dayOfWeek: null,
            startTime: null,
            active: false,
            has_error: false,
            error_msg: null
        }
    },
    mounted(){
      let options = {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            "locale": French
        }
        flatpickr("#add-start-time", options)
        flatpickr("#update-start-time", options)
    },
    watch: {
      selectedObject: {
              handler(){
                  if(this.selectedObject !== null) {
                      this.dayOfWeek = this.selectedObject.dayOfWeek
                      this.startTime = this.selectedObject.startTime
                      this.active = this.selectedObject.active
                  }
              },
              deep: true
          }
      },
    methods: {
      ...mapMutations({
        done: "DONE",
        setSelectedObject: 'SET_SELECTED_OBJECT'
      }),
      initForm(){
          this.dayOfWeek = null
          this.startTime = null
          this.active = false
          this.has_error = false
          this.error_msg = null
      },
      adding(){
        this.$apollo.mutate({
            mutation: ADD_TIME_SLOT,
            variables: {
                "dayOfWeek": this.dayOfWeek,
                "startTime": this.startTime
            },
            update: (d) => {
                console.log(d)
                console.log(`Time slot ${this.dayOfWeek} - ${this.startTime} add successfully`)
            }
          }).then(() => {
            this.initForm()
            this.done()
        }).catch((error) => {
            console.error(error)
            this.has_error = true
            this.error_msg = error
        })
      },
      updating(){
        this.$apollo.mutate({
            mutation: UPDATE_TIME_SLOT,
            variables: {
                "dayOfWeek": this.dayOfWeek,
                "startTime": this.startTime,
                "active": this.active,
                "uid": this.selectedObject.uid
            },
            update: (d) => {
                console.log(d)
                console.log(`Time slot ${this.dayOfWeek} on ${this.selectedObject.uid} updated successfully`)
            }
          }).then(() => {
            this.initForm()
            this.done()
        }).catch((error) => {
            console.error(error)
            this.has_error = true
            this.error_msg = error
        })
      }
    },
    computed: {
        ...mapGetters({
            slots: 'events/timeSlots',
            selectedObject: 'selectedObject'
        }),
        sortedTimeSlots(){
          return [...this.slots].sort((a,b) => a.dayOfWeek.localeCompare(b.dayOfWeek))
        },
        daysOfWeek(){
            return ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
        },
        headers(){
            return [
              {label: 'ID', name: 'uid', type: TEXT_TYPE},
              {label: 'Day of Week', name: 'dayOfWeek', type: TEXT_TYPE},
              {label: 'Time slot', name: 'startTime', type: TEXT_TYPE},
              {label: 'Active', name: 'active', type: BOOLEAN_TYPE},
              {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
            ]
        }
    }

}
</script>